<template>
  <q-layout view="hHh lpr lff" class="bg-grey-1">
    <!-- Sidebar/Drawer -->
    <q-drawer
      v-model="sidebarOpen"
      show-if-above
      :width="260"
      :breakpoint="1024"
      bordered
      class="bg-grey-2"
    >
      <q-list padding v-if="!loading">
        <router-link to="/my-races" class="justify-center back-button">
          <q-btn
            color="primary"
            :label="$t('dashboard.menu.backBtn')"
            icon="arrow_back"
          />
        </router-link>
        <SideBar />
      </q-list>
      <q-list padding v-else>
        <q-skeleton class="q-mx-sm q-my-sm" type="rect" height="50px" rounded />
        <q-skeleton class="q-mx-sm q-my-sm" type="rect" height="50px" rounded />
        <q-skeleton class="q-mx-sm q-my-sm" type="rect" height="50px" rounded />
        <q-skeleton class="q-mx-sm q-my-sm" type="rect" height="50px" rounded />
      </q-list>
    </q-drawer>
    <!-- Main dashboard layout -->
    <q-page-container>
      <q-page padding class="dashboard">
        <!-- Header -->
        <div class="dashboard-header">
          <q-btn
            v-if="!sidebarOpen"
            class="dashboard-header__drawer-button"
            flat
            @click="openSidebar"
            round
            dense
          >
            <q-icon name="east" />
            <q-icon name="space_dashboard" />
          </q-btn>
          <q-select
            v-model="selectedCurrencyOption"
            :options="currencyOptions"
            :label="$t('dashboard.menu.currencyBtn')"
            @update:model-value="handleCurrencyChange"
            class="currency-selector"
          />
        </div>
        <!-- Summary cards/Charts -->
        <div v-if="!loading && !currentRace" class="text-subtitle1">
          {{ $t("races.loadingDashboardMessage") }}
        </div>
        <div v-else class="dashboard-wrapper">
          <div class="dashboard-summary">
            <div class="dashboard-summary__info">
              <q-skeleton type="rect" height="100px" square v-if="loading" />
              <SummaryStats
                v-if="currentRace && currentRace.name && !loading"
                :loading="loading"
                :error="error"
                :race="currentRace"
              />
            </div>
            <q-card class="dashboard-summary__sales">
              <q-skeleton
                type="rect"
                height="100%"
                square
                v-if="loading"
                style="min-height: 190px"
              />
              <SummaryCardSales
                v-if="currentRace && currentRace.name && !loading"
                :race="currentRace"
              />
            </q-card>
            <q-card class="dashboard-summary__chart">
              <ChartTabs :race-id="Number($route.params.raceId)" />
            </q-card>
          </div>
          <!-- Orders/Shares tables -->
          <div class="dashboard-summary__table">
            <OrdersTable v-if="currentRace.id" :race-id="currentRace.id" />
            <SharesTable
              v-if="currentRace && currentRace.name"
              :race="currentRace"
            />
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ChartTabs from "@/pages/dashboardRaces/dashboardRacesSingle/components/charts/ChartTabs.vue";
import SummaryCardSales from "@/pages/dashboardRaces/dashboardRacesSingle/components/SummaryCardSales.vue";
import SummaryStats from "@/pages/dashboardRaces/dashboardRacesSingle/components/SummaryStats.vue";
import OrdersTable from "@/pages/dashboardRaces/dashboardRacesSingle/components/OrdersTable.vue";
import SharesTable from "@/pages/dashboardRaces/dashboardRacesSingle/components/SharesTable.vue";
import SideBar from "@/pages/dashboardRaces/dashboardRacesSingle/components/SideBar.vue";

export default {
  name: "DashboardRacesSinglePage",
  components: {
    SummaryStats,
    SummaryCardSales,
    ChartTabs,
    OrdersTable,
    SharesTable,
    SideBar,
  },
  data() {
    return {
      selectedCurrencyOption: null,
    };
  },
  computed: {
    ...mapState("dashboardRaces", [
      "links",
      "races",
      "raceId",
      "loading",
      "error",
      "ordersLoading",
      "ordersError",
      "isSidebarOpen",
    ]),
    ...mapGetters("dashboardRaces", [
      "getCurrentRace",
      "getCurrencies",
      "getCurrency",
    ]),
    currencyOptions() {
      return this.getCurrencies.map((currency) => ({
        label: currency,
        value: currency,
      }));
    },
    sidebarOpen: {
      get() {
        return this.isSidebarOpen;
      },
      set(value) {
        this.toggleSidebar(value);
      },
    },
    currentRace() {
      return this.getCurrentRace || {};
    },
    charts() {
      if (!this.currentRace || !this.currentRace.timeSeriesData) {
        return [];
      }
      return [
        {
          id: 1,
          data: this.currentRace.timeSeriesData.albumViewsOverTime || [],
          label: this.$t("dashboard.main.albumViews"),
          title: this.$t("dashboard.main.albumViews"),
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          borderColor: "rgba(54, 162, 235, 1)",
        },
        {
          id: 2,
          data: this.currentRace.timeSeriesData.ordersOverTime || [],
          label: this.$t("dashboard.main.totalOrders"),
          title: this.$t("dashboard.main.totalOrders"),
          backgroundColor: "rgba(255, 99, 132, 1)",
          borderColor: "rgba(255, 99, 132, 1)",
        },
        {
          id: 3,
          data: this.currentRace.timeSeriesData.salesAmountOverTime || [],
          label: this.$t("dashboard.main.totalAlbumSalesAmount"),
          title: this.$t("dashboard.main.totalAlbumSalesAmount"),
          backgroundColor: "rgba(255, 206, 86, 0.2)",
          borderColor: "rgba(255, 206, 86, 1)",
        },
      ];
    },
  },
  methods: {
    ...mapActions("dashboardRaces", [
      "fetchRaceById",
      "toggleSidebar",
      "setCurrency",
      "fetchCurrencies",
    ]),
    openSidebar() {
      this.toggleSidebar(true);
    },
    handleCurrencyChange(currency) {
      this.setCurrency(currency.value);
      this.fetchRaceById({
        raceId: this.$route.params.raceId,
        currency: currency.value,
      });
    },
  },
  async created() {
    this.selectedCurrencyOption = this.getCurrency;
    await this.fetchCurrencies();

    this.selectedCurrencyOption = this.getCurrency || this.getCurrencies[0];

    if (!this.getCurrentRace && !this.error) {
      this.fetchRaceById({
        raceId: this.$route.params.raceId,
        currency: this.selectedCurrencyOption,
      });
    }
  },
};
</script>

<style lang="scss">
.dashboard {
  margin: 0 auto;
  max-width: 2000px;

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-header {
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    &__drawer-button {
      width: 80px;
    }
  }
}

.dashboard-summary {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  gap: 10px;
  width: 100%;
  margin-bottom: 12px;

  &__info {
    margin: 0 auto;
    margin-bottom: 12px;
    min-width: 250px;
    height: 100%;
    flex-basis: 100%;
  }

  &__sales {
    flex-basis: 100%;
  }

  &__chart {
    overflow: hidden;
    width: 100%;
    flex-grow: 1;

    & div {
      text-transform: capitalize;
      font-weight: 600;
      width: auto;
      .q-tab__indicator {
        opacity: 1;
        background: #999;
      }
      .q-tab--active .q-tab__indicator {
        opacity: 1;
        background: $primary;
      }
    }
  }
  &__table {
    width: 100%;
    flex-basis: 100%;
  }
}

.back-button {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0 10px;
  margin-bottom: 10px;
  text-decoration: none;
  & > button {
    width: 90%;
    border-radius: 60px;
    align-items: center;
  }
}

.currency-selector {
  margin-left: auto;
  padding: 10px;
  width: 190px;
}

@media (min-width: $breakpoint-sm-min) {
  .dashboard-summary {
    &__chart {
      flex-basis: 100%;
    }

    &__table {
      width: 100%;
    }
  }
}

@media (min-width: $breakpoint-md-min) {
  .dashboard-summary {
    &__sales {
      flex-basis: 33%;
    }
    &__chart {
      flex-basis: 65%;
    }
  }
}
</style>
